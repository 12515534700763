/* about-container */
.about-container {
  background-color: #ffffff;
  padding: 5% 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.about-container h1 {
  color: #317ABE; 
  text-align: left; 
  -webkit-text-stroke: 1px #317ABE;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);  */
  font-size: 60px;
}

.about-container p {
  color: #9a99be; 
  text-align: left;
  -webkit-text-stroke: 1px #9a99be; 
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);  */
  font-size: 45px;
  font-weight: 1000;
}

/* purpose-container */
.purpose-container {
  background-color: #000000;
  padding: 5% 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.purpose-container h1 {
  color: #f99417; 
  text-align: left; 
  -webkit-text-stroke: 1px #f99417;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);  */
  font-size: 60px;
}

.purpose-container p {
  color: #9a99be; 
  text-align: left;
  -webkit-text-stroke: 1px #9a99be; 
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);  */
  font-size: 45px;
  font-weight: 1000;
}

@media screen and (max-width: 768px) {
  .purpose-container {
    padding: 15% 5%;
  }

  .purpose-container h1 {
    font-size: 40px;
  }

  .purpose-container p {
    font-size: 30px;
  }
}

@media screen and (max-width: 768px) {
  .about-container {
    padding: 15% 5%;
  }

  .about-container h1 {
    font-size: 40px;
  }

  .about-container p {
    font-size: 30px;
  }
}