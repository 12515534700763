.our-board-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 16px;
    background-color: #010102;
  }
  
  .section-title {
    margin-top: 20px;
    margin-bottom: 80px;
    color: #f5f5f5;
    font: 700 51px/120% Syne, sans-serif;
    text-align: center;
    letter-spacing: -1.02px;
  }

  .team-member-card {
    flex: 1;  
    max-width: 100%;
    min-width: 200px;  
    padding: 20px;
  }
  
  .team-member-image {
    height: 256px;
    border-radius: 32px;
    background-color: #b3b3b3;
    background-size: cover;
    background-position: center;
    aspect-ratio: 5 / 4;
  }
  
  .team-member-name {
    margin-top: 16px;
    color: #f5f5f5;
    font: 18px/140% "Work Sans", sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .team-member-role {
    color: #fff;
    font: 14px/150% "Work Sans", sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 4px 0 10px 0;
  }
  

.board-slider-container {
    width: 80%;  
    height: 100%;  
    margin: 0 auto;  
  }
  
   
  .slider-slide {
    outline: none;  
  }
  
   
  .slider-arrow {
    background-color: rgba(0, 0, 0, 0.5);  
    color: #fff;  
    font-size: 24px;  
    width: 40px;  
    height: 40px;  
    line-height: 40px;  
    border-radius: 50%;  
  }
  
   
  .slider-arrow:before {
    content: "";  
  }
  
   
  .slider-prev:before,
  .slider-next:before {
    content: "\u25C4";  
  }
  
  .slider-next:before {
    content: "\u25BA";  
  }
  
  @media (max-width: 991px) {
    .our-board-section {
      padding: 0 20px;
    }
  
    .section-title {
      margin-top: 40px;
      font-size: 40px;
    }
  
    .team-members-grid {
      flex-direction: column;
      gap: 32px;
    }
  
    .team-member-card {
      width: 100%;
      max-width: none;  
    }
  }
  