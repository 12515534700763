.header {
    position: fixed;
    height: 70px;
    width: 100%;
    margin: 0;
    z-index: 1;
    transition: .5s ease-in;
    overflow: visible;
    background-color: transparent;
}

.tab-button b{
    font-family: "Open Sans";
    font-weight: 450;
    font-size: 16px;
    text-shadow: 0px 0px 15px rgba(0, 0, 0, 1);
}

.header-bg {

    background-color: #317ABE;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;

}


.navbar__logo {
    width: 250px;
    height: auto;
    -webkit-filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 1));
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 1));
}

.header .nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
}

.default-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 3rem;
}

.register-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-item {
    padding: 0 0 0 12px;
}

.tab-button {
    padding: 12px 25px;
    border: none;
    text-align: center;
    transition-duration: 0.5s;
    color: white;
    text-decoration: inherit;
    cursor: pointer;
    border-radius: 8px;
    border:  1.5px solid transparent;
}

.tab-button-login {
    background-color: transparent;
    border:  1.5px solid whitesmoke;
}

.tab-button-join {
    background-color: #F99417;
    border:  1.5px solid #F99417;
}

.tab-button:hover{
    border:  1.5px solid white;
}

.tab-button-login:hover {
    background-color: #334daa;
    border:  1.5px solid #334daa;
}

.tab-button-join:hover {
    background-color: #ff7700;
    border: 1.5px solid #ffffff;
    color: whitesmoke;
}

.hamburger {
    width: auto;
    margin: 0 1rem 0 0;
    display: none;
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 1));
}

@media screen and (max-width:940px) {

    .hamburger {
        display: block;
    }

    .default-tabs {
        flex-direction: column;
        padding: 0;
    }
    
    .register-tabs {
        flex-direction: column;
        margin-bottom: 1rem;
    }

    .nav-menu {
        position: fixed;
        top: -120%;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        z-index: 999;
        left: 0;
        text-align: center;
        transition: .5s;
        list-style-type: none;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .nav-menu.active {
        display: flex;
        justify-content: space-around;
        top: 70px;
        height: 50%;
        left: 0;
        background: rgb(49, 122, 190);
        background: linear-gradient(180deg, rgba(49, 122, 190, 1) 0%, rgba(49, 122, 190, 0.813958859911152) 54%, rgba(49, 122, 190, 0.3881885517879027) 100%);
        -webkit-backdrop-filter: blur(5px);
        transition: .6s;
        backdrop-filter: blur(5px);
        list-style-type: none;
    }

    .nav-item {
        margin: 1.5rem 0;
        padding: 0;
    }

    .navbar__logo{
        width: 150px;
    }
}