.card{
    background-color: #00000000;
    display: flex;
    justify-content: center;
    margin: 0;
    display: grid;
    place-content: center;
    width: var(--width); /* Set width to 400 pixels */
    height: var(--height); /* Set height to 400 pixels 400 */
    padding-left: 20px;
    padding-right: 20px;
    color: white;
}

.card img{
    width: 100%; /* Set your desired width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px;
    transition: transform 0.01s ease; /* Add smooth transition */
    opacity: 0.8;
    transition: opacity 0.3s ease; /* Add smooth transition */
    padding-bottom: 5px;
}

.card img:hover{
    opacity: 1; /* Set opacity to 1 on hover */
    transform: scale(1.1); /* Scale the image up by 10% */
}
.card h3{
    text-align: var(--position);
}

.card p{
    text-align: var(--position);
}
