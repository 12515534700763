.event-card {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s, transform 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
}

.event-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.event-image {
  width: 100%;
  height: 50%;
  object-fit: cover;
  transition: opacity 0.3s;
}

.event-info {
  padding: 16px;
  transition: opacity 0.3s;
}

.event-title {
  margin: 0;
  font-size: 1.5em;
}

.event-date {
  margin-top: 5px;
  margin-bottom: 10px;
  color: #f99417;
  font-size: 1.5em;
}

.event-description {
  margin: 0;
}

.event-long-description {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #4F6B8A;
  color: white;
  font-size: auto;
  overflow-y: auto;
  box-sizing: border-box;
  transition: opacity 0.3s;
}

.event-card:hover .event-long-description {
  display: block;
  opacity: 1;
}

.event-card:hover .event-image,
.event-card:hover .event-info {
  opacity: 0;
}
