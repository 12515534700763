body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; 
}
.protons-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 80px 20px;
    background-image: url(../assets/bg-intro-mobile.png);
    background-color: #4f6b8a;
    background-position: center;
    text-align: left;
    min-height: 100vh;
    box-sizing: border-box;
    width: 100%;
}
.protons_logo, h1 {
    align-self: center; 
    text-align: center;
}
.protons_logo {
    max-width: 250px;
    width: 100%;
    height: auto;
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 1));
    margin-bottom: 20px;
}

h1 {
    font-size: 3rem;
    font-weight: bold;
    color: #fff;
    letter-spacing: 2px;
    background-color: rgba(255, 255, 255, 0.3); 
    padding: 10px 20px;
    border-radius: 10px;
    display: inline-block; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
    margin: 10px 0;
}

h1 span {
    background: linear-gradient(90deg, #5f2c82 0%, #49a09d 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.8));
    animation: flicker 1.5s infinite alternate;
}
.intro-section h2 {
    color: #f99417;
}
.video-section h2 {
    color: #f99417;
}
.intro-section {
    text-align: left; 
    max-width: 800px;
    margin-left: 20px;
    width: 100%;
    color: #fff;
}

.video-section {
    text-align: left;
    width: 80%;
}

.video-section h3 {
    color: #f99417;
    margin-bottom: 10px;
}

.video-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; 
    gap: 20px;
    padding: 20px;
    width: 100%;
}

@keyframes flicker {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.9;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .video-card {
        flex-direction: column; 
        align-items: flex-start; 
    }

    .content {
        margin-right: 0; 
        margin-bottom: 20px;
    }

    .video-container {
        width: 100%; 
        padding-bottom: 0;
        height: auto;
    }
}