.video-card {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px 0; /* Space between cards */
    padding: 20px;
    display: flex; /* Use flexbox to align items */
    justify-content: space-between; /* Space between content and video */
    width: 100%; /* Ensure the card takes the full width */
    box-sizing: border-box; /* Include padding and border in the element's total width */
    height: auto; /* Set height to auto to fit content */
}

.content {
    flex: 1; /* Allow the content to take available space */
    margin-right: 20px; /* Space between content and video */
}

.video-container {
    flex: 0 0 300px; /* Fixed width for the video container */
    position: relative;
    height: 0; /* Height should be 0 for the aspect ratio trick */
    padding-left: 5%;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: none;
}

.resources {
    margin-top: 10px; /* Space above resources */
}

.resources h3 {
    margin-bottom: 5px; /* Space below the resources title */
}

.resources ul {
    list-style: none; /* Remove default list styles */
    padding-left: 0; /* Remove padding */
}

.resources ul li {
    margin-top: 5px; /* Space between resource items */
}

.resources ul li a {
    color: #3498db; /* Link color */
    text-decoration: none; /* No underline */
}

.resources ul li a:hover {
    text-decoration: underline; /* Underline on hover */
}
/* Media Query for Responsive Design */
@media (max-width: 768px) {
    .video-card {
        flex-direction: column; /* Stack items vertically */
        align-items: flex-start; /* Align items to the start */
    }

    .content {
        margin-right: 0; /* Remove margin on small screens */
        margin-bottom: 20px; /* Add space below content */
    }

    .video-container {
        width: 100%; /* Make video container full width */
        padding-bottom: 0; /* Reset padding for smaller screens */
        height: auto; /* Allow the height to adjust */
    }
}