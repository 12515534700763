.flex-container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: space-between;
}

.section1 h1{
  padding-top: 50px;
}

.section1 {
  background-color: #00629B;
}

.section2 {
  background-color: #4F6B8A;
}

.title-table {
  display:table; /*acts like a table*/
  padding-left: 30px;
}

.title-cell {
  vertical-align:middle; /*middle centred*/
  display:table-cell; /*acts like a table cell*/
}

.title-text h1 {
  color: white;
  font-size: 60px;
  padding-bottom: 10px;
}

.title-text h3 {
  font-size: 200%;
  color: white;
}

.title-text p {
  font-size: 100%;
  color: white;
}

.title-cell img{
  width: 90%;
  height: auto;
}